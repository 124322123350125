import { Action } from './base/action.model';

export enum AppActionNames {
  STORE_STATE = '[APP] Store State',
  STORE_TOKEN = '[APP] Store Token',
  STORE_INFO_USER = '[APP] Store Info User', 
  STORE_THEME = '[APP] Store Theme', 
  STORE_SOURCE_INFO = '[APP] Store SourceInfo', 
  GET_ALL_RESOURCE = '[APP] Get All Resource',
  GET_PRODUCTS = '[APP] Get Products',
  GET_INFO_USER = '[APP] Get Info User',
  GET_RESOURCE_PLANNER = '[APP] Get Resource Planner',
  OPEN_OTP = '[APP] Open OTP',
  SET_TIME_OTP = '[APP] Set Time OTP',
  SET_DATA_OTP = '[APP] Set Data OTP',
}
export class StoreState implements Action {
  static readonly type = AppActionNames.STORE_STATE;
  constructor(public payload: any) {}
}
export class StoreToken implements Action {
  static readonly type = AppActionNames.STORE_TOKEN;
  constructor(public payload: any) {}
}
export class StoreTheme implements Action {
  static readonly type = AppActionNames.STORE_THEME;
  constructor(public payload: any) {}
}
export class StoreSourceInfos implements Action {
  static readonly type = AppActionNames.STORE_SOURCE_INFO;
  constructor(public payload: any) {}
}
export class StoreInfoUser implements Action {
  static readonly type = AppActionNames.STORE_INFO_USER;
  constructor(public payload: any) {}
}
export class GetInfoUser implements Action {
  static readonly type = AppActionNames.GET_INFO_USER;
  constructor(public payload?: any) {}
}
export class GetAllResource implements Action {
  static readonly type = AppActionNames.GET_ALL_RESOURCE;
  constructor(public payload?: any) {}
}
export class GetResourcePlanner implements Action {
  static readonly type = AppActionNames.GET_RESOURCE_PLANNER;
  constructor(public payload?: any) {}
}
export class GetProducts implements Action {
  static readonly type = AppActionNames.GET_PRODUCTS;
  constructor(public payload?: any) {}
}
export class OpenOTP implements Action {
  static readonly type = AppActionNames.OPEN_OTP;
  constructor(public payload?: any) {}
}
export class SetTimeOTP implements Action {
  static readonly type = AppActionNames.SET_TIME_OTP;
  constructor(public payload?: any) {}
}
export class SetDataOTP implements Action {
  static readonly type = AppActionNames.SET_DATA_OTP;
  constructor(public payload?: any) {}
}

