import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { Constant } from '../../constant';

@Component({
  selector: 'app-chart-js-stacked-bar-chart',
  templateUrl: './chart-js-stacked-bar-chart.component.html',
  styleUrls: ['./chart-js-stacked-bar-chart.component.scss']
})
export class ChartJsStackedBarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() height: number = 250;
  @Input() noLabels: boolean = false;
  @Input() noBorderGrid: boolean = false;
  isReload: boolean = false;
  isDevice: boolean = false;
  id: number = Math.round(Math.random() * Math.random() * Math.random() * 1000);
  public chart: any;
  width: any;

  @Input() chartData: any = {
    labels: [''],
    datasets: [
      {
        label: '',
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 4
      }
    ]
  };
  constructor() {}
  ngOnInit() {
    if (typeof window !== 'undefined') {
      this.width = window.innerWidth;
      this.isDevice = Constant.checkAndroid() || Constant.checkIOS() ? true : false;
    }
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    const changes: any = simpleChanges;
    if (changes.chartData.currentValue) {
      setTimeout(() => {
        this.createChart();
      }, 1000);
    }
  }
  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chartData.datasets.forEach((item: any) => {
      console.log(item);
    });
    this.chartData.labels = this.noLabels ? this.chartData.labels.map((item: any) => {return '';}) : this.chartData.labels;
    this.chart = new Chart('barChart' + this.id, {
      type: 'bar',
      data: this.chartData,
      options: {
        plugins: {
          title: {
            display: false,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
    });
  }
}
