
<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">Ghi chú</h5>
    <button type="button" class="close" (click)="dismiss()">
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="46" viewBox="0 0 49 46" fill="none">
            <path d="M30.3473 16.9703L18.2084 28.284M18.2084 16.9703L30.3473 28.284" stroke="#828282"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </button>
</div>
  <div class="modal-body">
    <input-note 
      [value]="value" (valueChange)="value = $event" [isDisabled]="isReadonly"
      [maxLength]="maxLength" [background]="isReadonly ? '#FFFADE' : 'white'" [rows]="4"
    ></input-note>
  </div>
  <div class="modal-footer">
    <button class="btn btn-green w-100 common__btn" (click)="save()" *ngIf="!isReadonly">
      {{'Lưu' | translate}}
    </button>
  </div>
</div>
